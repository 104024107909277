import React from 'react';

export const BackIcon = ({theme} : any) => {
    return (
        theme !== 'darkmode' ? 
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
            <g clip-path="url(#clip0_6577_8020)">
            <path d="M0 6.99611C0 7.15192 0.029262 7.29864 0.0877859 7.43628C0.14631 7.57392 0.239185 7.70507 0.366411 7.8297L6.24427 13.6961C6.43766 13.8987 6.6743 14 6.95419 14C7.14758 14 7.32315 13.9519 7.48089 13.8558C7.63866 13.7598 7.76461 13.6338 7.85875 13.478C7.95292 13.3222 8 13.1456 8 12.9482C8 12.6626 7.89058 12.4081 7.67173 12.1847L2.45038 6.99611L7.67173 1.80745C7.89058 1.58931 8 1.33482 8 1.04396C8 0.851789 7.95292 0.676497 7.85875 0.518086C7.76461 0.359673 7.63866 0.233723 7.48089 0.140233C7.32315 0.0467444 7.14758 0 6.95419 0C6.6743 0 6.43766 0.0986829 6.24427 0.296049L0.366411 6.16248C0.244274 6.28714 0.153944 6.41699 0.0954198 6.55203C0.0368956 6.68707 0.00508903 6.83509 0 6.99611Z" fill="black"/>
            </g>
            <defs>
            <clipPath id="clip0_6577_8020">
            <rect width="8" height="14" fill="white"/>
            </clipPath>
            </defs>
        </svg>
         : 
         <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
            <g clipPath="url(#clip0_5440_11380)">
                <path d="M0 6.99611C0 7.15192 0.029262 7.29864 0.0877859 7.43628C0.14631 7.57392 0.239185 7.70507 0.366411 7.8297L6.24427 13.6961C6.43766 13.8987 6.6743 14 6.95419 14C7.14758 14 7.32315 13.9519 7.48089 13.8558C7.63866 13.7598 7.76461 13.6338 7.85875 13.478C7.95292 13.3222 8 13.1456 8 12.9482C8 12.6626 7.89058 12.4081 7.67173 12.1847L2.45038 6.99611L7.67173 1.80745C7.89058 1.58931 8 1.33482 8 1.04396C8 0.851789 7.95292 0.676497 7.85875 0.518086C7.76461 0.359673 7.63866 0.233723 7.48089 0.140233C7.32315 0.0467444 7.14758 0 6.95419 0C6.6743 0 6.43766 0.0986829 6.24427 0.296049L0.366411 6.16248C0.244274 6.28714 0.153944 6.41699 0.0954198 6.55203C0.0368956 6.68707 0.00508903 6.83509 0 6.99611Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_5440_11380">
                <rect width="8" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};