import axios from 'axios';
import { AuthLoginType, AuthLoginMSType } from '@shared/types/Auth.type';
import { AUTH, HOST_URL } from '../constant/api.contant';
import axiosInstance from "./restful.service";
import { User, UserManager } from "oidc-client-ts";
import userManager from '../../utils/userManager';

const login = () : Promise<void> => {
  return userManager.signinRedirect();
};

const getUser = () : Promise<User | null> => {
  return userManager.getUser();
}

const loginCallback = (): Promise<User> => {
  return userManager.signinRedirectCallback();
}

const logout = () : Promise<void> => {
  return userManager.signoutRedirect();
};

const AuthService = {
  login,
  logout,
  loginCallback,
  getUser
};

export default AuthService;
