import React, { useEffect } from 'react';
// import { useAuth } from '@core/contexts/Auth/AuthContext';
import { Outlet, useLocation } from "react-router-dom";

const RequireAuth: React.FC<{ children: JSX.Element }> = ({ children }: { children: JSX.Element }) => {
  // const auth = useAuth();
  const location = useLocation();
  // const [searchParams] = useSearchParams();
  // const typeURL = searchParams.get("type");

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location]);

  // if (typeURL === "Mobile" && !auth?.user) {
  //   return <Navigate to={`/error-page?type=Mobile`} state={{ from: location }} replace />;
  // }

  // if (typeURL !== "Mobile" && !auth?.user) {
  //   // đang bỏ qua validate, cho vào thằng trang chủ
  //   return <Navigate to="/" state={{ from: location }} replace />;
  // }

  return children;
}

const MasterLayout = () => {
  return (
    <RequireAuth>
      <>
          <div className='min-w-0 min-h-screen flex flex-col'>
              <Outlet />
          </div>
      </>
    </RequireAuth>
  )
}

export default MasterLayout