import React from "react";
import { Routes, Route } from "react-router-dom";
import { LoadingBarProcess } from "@shared";
import CallBackPage from "pages/callBack/CallBackPage";
import { PageNotFound } from "pages/404/PageNotFound";
import { Page505 } from "pages/404/Page505";
import { PageForbidden } from "pages/404/PageForbidden";
import { PageError } from "pages/404/PageError";
import HomeRoutes from "./HomeRoutes";
import { Maintain } from "pages/404/maintain";
import LoadingPage from "pages/LoadingPage/LoadingPage";
import MasterLayout from "@shared/layouts/MasterLayout";
import PromotionListRoutes from "./PromotionListRoutes";
import Gift from "pages/Gift/Gift";
import TititadaGoldRoutes from "./TititadaGoldRoutes";

const routes = () => {

  return (
    <>
      <Routes>
        <Route path="/" element={<HomeRoutes />} />
        <Route path="/tttd-gold/*" element={<TititadaGoldRoutes />} />
        <Route path="/promotion-list" element={<PromotionListRoutes />} />
        <Route path="/callback" element={<CallBackPage />}></Route>
        <Route element={<MasterLayout />}>
          <Route
            path="*"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageNotFound />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/505"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <Page505 />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/404"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageNotFound />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/forbidden"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageForbidden />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/error-page"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <PageError />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/loading-page"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <LoadingPage />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/maintain"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <Maintain />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<MasterLayout />}>
          <Route
            path="/gift"
            element={
              <React.Suspense fallback={<LoadingBarProcess />}>
                <Gift />
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default routes;
