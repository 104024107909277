import { EVENTS, GIFT, HOST_URL, HOST_URL_TRADING } from "@core/constant/api.contant"; 
import axiosInstance, { RestfulService, axiosInstanceTrading } from "./restful.service";

const homeEvent = () => {
    return axiosInstance.get(HOST_URL + EVENTS.HOME)
}

const allEvent = (params: any) => {
    return axiosInstance.get(HOST_URL + EVENTS.ALL_EVENT, {params})
}

const gift = (id : string) => {
    return axiosInstanceTrading.post(HOST_URL_TRADING + '/api/v6' + GIFT.GET_RECEIVE_DETAIL(id))
}

export const EventService = {
    homeEvent,
    allEvent,
    gift
}