import React, { useEffect } from "react";
import { Spinner } from "@shared/components/spinner/Spinner";
import { useAuth, hasAuthParams } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
// import { ConfigService } from "@core/services/config.service";
// import { AccountService } from "@core";

const CallBackPage = () => {
    const auth = useAuth();
    const navigator = useNavigate();
    const location = useLocation();

    useEffect(() => {        
        if (!hasAuthParams() &&
            !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect({
                state: location?.pathname
            });            
        } else if (auth.isAuthenticated) {            
            // Promise.all([AccountService.getProfile(), ConfigService.getConfigAll()]).then((values) => {
            //     const userProfileRes = values[0];

            //     if (userProfileRes?.data) {
            //         const resContent = JSON.parse(userProfileRes.data);

            //         if (resContent.status === 1) {
            //             sessionStorage.setItem('userProfile', JSON.stringify(resContent.data));
            //         } else {
            //             sessionStorage.removeItem('userProfile');
            //         }
            //     } else {
            //         sessionStorage.removeItem('userProfile');
            //     }
            // }, () => {
            //     sessionStorage.removeItem('userProfile');
            // }).finally(() => {
            //     const from: any = auth.user?.state || "/";
            //     navigator(from, { replace: true });
            // });

            // const from: any = auth.user?.state || "/";
            // const getConfig = () => {
            //     ConfigService.getConfigAll().finally(() => navigator(from, { replace: true }));
            // }
            // if(!localStorage.getItem('config')) {                                     
            //     getConfig();
            // } else {
            //     navigator(from, { replace: true });
            // }
        }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    return (
        <>
            <div className="flex">
                <Spinner />
            </div>
        </>
    )
}

export default CallBackPage;