import { UserManager } from 'oidc-client-ts';

const userManagerConfig = {
  client_id: 'promotion_web',
  redirect_uri: `${process.env.REACT_APP_DOMAIN}/vong-quay-may-man/callback`,
  response_type: 'code',
  scope: 'openid profile email phone promotion-services',
  authority: `${process.env.REACT_APP_AUTHORITY}`,
  // silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  automaticSilentRenew: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  post_logout_redirect_uri: `${process.env.REACT_APP_DOMAIN}/vong-quay-may-man`,
};

// const userManagerConfig = {
//   client_id: 'promotion_web',
//   redirect_uri: `${process.env.REACT_APP_DOMAIN}/callback`,
//   // redirect_uri: `http://localhost:3000/callback`,
//   response_type: 'code',
//   scope: 'openid profile email phone promotion-services',
//   // authority: 'https://e390-113-23-34-85.ap.ngrok.io',
//   authority: `${process.env.REACT_APP_AUTHORITY}`,
//   // silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
//   automaticSilentRenew: false,
//   filterProtocolClaims: true,
//   loadUserInfo: true,
//   post_logout_redirect_uri: `http://localhost:3000/lac-hong-bao`,
// };

const userManager = new UserManager(userManagerConfig);

export default userManager;
