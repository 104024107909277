import React, { useEffect, useState } from "react";
import shadow from "assets/img/shadow.png";
import bow from "assets/img/cai_no.png";
import lid from "assets/img/nap.png";
import box from "assets/img/hop.png";
import confetti from "canvas-confetti";
import { EventService } from "@core/services/event.service";
import { closeFuncWithMess, closeFuncWithPayload, playSound } from "assets/js/gift_style";
import sound from "assets/sound/jingle.mp3";

const Gift = () => {
  const [error, setError] = useState<string>();
  const [giftObj, setGiftObj] = useState<any>();
  const [caiNoStyle, setCaiNoStyle] = useState<boolean>(false);
  const [giftSectionStyle, setGiftSectionStyle] = useState<boolean>(false);
  const [fullNapStyle, setFullNapStyle] = useState<string>();
  const [rewardOutStyle, setRewardOutStyle] = useState<boolean>(false);
  const [isShowBtn, setIsShowBtn] = useState<boolean>(false);
  const [noShakingStyle, setNoShakingStyle] = useState<boolean>(false);
  const [senderName, setSenderName] = useState<string>("");

  const duration = 30 * 1000;
  const animationEnd = Date.now() + duration;
  const defaults = { startVelocity: 12, spread: 300, ticks: 60, zIndex: 0 };

  const randomInRange = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  const soundOpen = new Audio(sound);

  const AudioContext =
    window.AudioContext || (window as any).webkitAudioContext;

  const context = new AudioContext(); // Make it crossbrowser

  const b = document.body;
  const events = ["touchstart", "touchend", "mousedown", "keydown"];
  events.forEach((e) => b.addEventListener(e, unlock, false));
  function unlock() {
    context.resume().then(clean);
  }
  function clean() {
    events.forEach((e) => b.removeEventListener(e, unlock));
  }

  const getData = async () => {
    let user: any = localStorage.getItem("user");
    if (user) {
      user = JSON.parse(user);
      const gift_id = user.account_gift_id;
      setSenderName(user.sender_name);
      try {
        const response = await EventService.gift(gift_id);

        if (response?.status === 200) {
          const giftData = JSON.parse(response?.data);
          if (
            giftData?.data !== "BOND" &&
            giftData?.data !== "STOCK" &&
            giftData?.data !== null
          ) {
            // if ("AudioContext" in window || "webkitAudioContext" in window) {
            //   soundOpen.play();
            // }
            playSound();

            setGiftObj(giftData?.data);
            openGift();
          } else {
            if (giftData?.data === "BOND" || giftData?.data === "STOCK") {
              setIsShowBtn(true);
              setGiftObj(giftData?.data);
              setError((giftData?.error_message).split("|")[1]);
            } else {
              setError((giftData?.error_message).split("|")[1]);
            }
          }
        } else {
          setError("(01) Hệ thống đang xử lí quà tặng, bạn quay lại sau nhé");
          return;
        }
      } catch (error) {
        setError("(02) Hệ thống đang xử lí quà tặng, bạn quay lại sau nhé");
      }
    } else {
      setError("(03) Hệ thống đang xử lí quà tặng, bạn quay lại sau nhé");
    }
  };

  const openGift = () => {
    setCaiNoStyle(true);
    setGiftSectionStyle(true);

    setNoShakingStyle(true);
    setTimeout(function () {
      setFullNapStyle("none");
      setFullNapStyle("open-nap");

      confetti({
        particleCount: 120,
        spread: 60,
        origin: { y: 0.6 },
        startVelocity: 40,
        scalar: 1,
      });

      // ButtonPlay(yodelBuffer);
      const interval: any = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          return clearInterval(interval);
        }

        const particleCount = 40 * (timeLeft / duration);
        // since particles fall down, start a bit higher than random
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: {
              x: randomInRange(0.1, 0.3),
              y: Math.random() - 0.2,
            },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: {
              x: randomInRange(0.7, 0.9),
              y: Math.random() - 0.2,
            },
          })
        );
      }, 250);

      setRewardOutStyle(true);
    }, 1000);

    setIsShowBtn(true);
  };

  useEffect(() => {

    getData();
  }, []);

  const handleClose = () => {
    closeFuncWithPayload("close");
  };

  const handleWatchDetail = (giftObj: any) => {
    if (giftObj?.gift_type === 0 || giftObj === "STOCK") {
      closeFuncWithMess("19");
    } else {
      closeFuncWithMess("20");
    }
  };

  return (
    <div className="relative h-[100vh] flex-col flex-nowrap">
      <div id="event_banner"></div>
      <div className="head_section relative px-[23px] pt-[38px] flex-1 border-solid border-0 border-red">
        <h1 className="text-[26px] font-bold leading-[43.808px] tracking-[-1px] min-[321px]:mb-[9px] min-[321px]:text-[37px]">
          Chúc mừng bạn,
        </h1>
        <p className="text-[14px] font-medium opacity-60 max-w-[227px] min-[321px]:text-[15px]">
          Đã nhận được phần quà từ {senderName} nhé.
        </p>
      </div>
      <div className="flex flex-col justify-around h-[calc(100%-168px)]">
        <div className="flex flex-col gap-y-10">
          <div
            className={`reward border-[0px] border-solid border-red text-center absolute w-[100%] h-[40%] mt-[80px] z-10 flex justify-center ${
              rewardOutStyle ? "reward_out" : "hidden"
            }`}
          >
            <p
              className={`absolute w-[72%] ${
                error
                  ? "bottom-[6%] max-[320px]:bottom-[-12%]"
                  : "bottom-[16%] max-[320px]:bottom-[-2%]"
              } min-[376px]:bottom-[30%] font-secondary z-[999] uppercase font-bold ${
                giftObj?.gift_type === 0
                  ? "text-[54px] leading-[75.6px] tracking-[2.56px] before:top-0 after:left-0 after:top-0 after:right-0 min-[321px]:text-[64px]"
                  : "text-[24px] leading-[30px] tracking-[0.96px] before:left-0 before:top-0 after:left-0 after:top-0"
              }`}
              title={giftObj?.gift_item}
            >
              {giftObj?.gift_item}
            </p>
            <p
              className={`shadow-item absolute w-[72%] ${
                error
                  ? "bottom-[6%] max-[320px]:bottom-[-12%]"
                  : "bottom-[16%] max-[320px]:bottom-[-2%]"
              }  min-[376px]:bottom-[30%] font-secondary z-[999] uppercase font-bold ${
                giftObj?.gift_type === 0
                  ? "text-[54px] leading-[75.6px] tracking-[2.56px] before:top-0 after:left-0 after:top-0 after:right-0 min-[321px]:text-[64px]"
                  : "text-[24px] leading-[30px] tracking-[0.96px] before:left-0 before:top-0 after:left-0 after:top-0"
              }`}
              title={giftObj?.gift_item}
            >
              {giftObj?.gift_item}
            </p>
          </div>
          <div
            className={`text-center relative border-[0px] border-solid border-green h-[380px] w-[100%] flex items-end justify-center origin-bottom z-0 ${
              giftSectionStyle ? "" : "gift_section"
            }`}
          >
            <div className={`full_box`}>
              <div
                className={`relative border-[0px] border-solid border-blue w-[100%] z-[1] pr-1 mb-[-70px] ${
                  noShakingStyle && "full_nap"
                } ${fullNapStyle === "open-nap" ? "open-nap" : ""}`}
              >
                <div
                  className={` relative top-10 w-[100%] z-[2] ${
                    caiNoStyle ? "" : "cai_no"
                  }`}
                >
                  <img alt="Nơ" src={bow} />
                </div>
                <div className="relative">
                  <img alt="Nắp" src={lid} />
                </div>
              </div>
              <div className="border-[0px] border-solid red-green relative w-[100%]">
                <img alt="Hộp" src={box} className="z-10" />
              </div>
            </div>
            <div
              className={`border-[0px] border-solid border-red text-center absolute flex justify-center items-end w-[100%] h-[57%] bottom-[-100px] z-[-1]`}
            >
              <img src={shadow} alt="Shadow" className="mb-[34px]" />
            </div>
          </div>
          <div className="relative pb-[5px] px-3 text-center w-[100%] flex justify-center items-end text-[#FF7337] text-[16px] font-semibold border-[0px] border-solid border-blue">
            {error}
          </div>
        </div>
        <div className="z-50">
          {(error || isShowBtn) && (
            <div
              className={`text-center pb-[6px] border-[0px] border-solid border-black ${
                error && "mt-3"
              }`}
            >
              <button
                className="bg-[#fff] border-none text-black no-underline block text-[17px] font-bold cursor-pointer rounded-[100px] w-[330px] h-[57px] tracking-[-0.165] mx-auto"
                id="openGift"
                onClick={handleClose}
              >
                Quay về
              </button>
            </div>
          )}

          {isShowBtn && (
            <div className="text-center border-[0px] border-solid border-black">
              <button
                className="bg-[#F4D942] border-none text-black no-underline block text-[17px] font-semibold cursor-pointer rounded-[100px] w-[330px] h-[56px] tracking-[-0.165] mx-auto btn_getreward"
                id="openGift"
                onClick={() => {
                  handleWatchDetail(giftObj);
                }}
              >
                Xem chi tiết
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Gift;
