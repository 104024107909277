export const HOST_URL = process.env.REACT_APP_API_ENDPOINT;
export const HOST_URL_TRADING = process.env.REACT_APP_TRADING_API_ENDPOINT;

export const AUTH = {
  SIGN_IN: "/auth/sign-in",
  SIGN_UP: "/auth/sign-up",
  LOGOUT: "/auth/logout"
}

export const EVENTS = {
  HOME: '/service-master/api/v1/promotion/home',
  ALL_EVENT: '/service-master/api/v1/promotion/home/going-on'
}

export const CATEGORIES = {
  CHART: (account_type: string, chart_type: string) => `/portfolio/total-asset/chart/${account_type}/${chart_type}`,
  TOTAL_ASSET: (account_type: string) => `/portfolio/total-asset/${account_type}`,
  CASH: (account_type: string) => `/wallet/${account_type}/cash`,
  PORTFOLIO: () => `/account/portfolio`,
  INVEST: () => `/tititada-master/bonds/invest`,
  ADVANCE_MONEY: () => `/advance-money`,
  GROWTH_PORTFOLIO: '/portfolio/growth-portfolio'
}

export const GIFT = {
  GET_RECEIVE_DETAIL: (id : string) => `/account-gift/receive/${id}`
}