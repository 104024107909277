import React, { useState, ReactNode, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InternalAxiosRequestConfig } from "axios";
import jwt from "jwt-decode";
import AuthContext from "./AuthContext";
import { NormalResponseError } from "@shared/types/Common.type";
import AuthService from "@core/services/auth.service";
import axiosInstance from "@core/services/restful.service";
import { getToken } from "assets/js/gift_style";

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<any>();
  const [error, setError] = useState<NormalResponseError>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeURL = searchParams.get("type");
  const deviceURL = searchParams.get("device");
  const mobilePaddingTop = searchParams.get("paddingTop");
  const routeGift = window.location.pathname;
  const activeDate = searchParams.get('activeDate');
  const expireDate = searchParams.get('expireDate');
  const show_welcome = searchParams.get('show_welcome');
  const theme = searchParams.get('theme');

  const axiosInstanceIntceptorWithCookie = (
    config: InternalAxiosRequestConfig
  ) => {
    const newHeaders = { ...config };
    return newHeaders;
  };

  const getUserToken = async () => {
    const response = await getToken();

    const timeoutId = setTimeout(() => {
      if (!response) {
        setLoadingInitial(false);
        navigate(`/error-page?type=Mobile&device=${deviceURL}`);
        return;
      }
    }, 1000);

    if (response) {
      let currentUser: any =
        localStorage.getItem("user") == null
          ? null
          : JSON.parse(localStorage.getItem("user") as string);
      let user_token_info_raw: any = JSON.parse(response as string);
      let user_token_info: any = user_token_info_raw.key;
      clearTimeout(timeoutId);

      if (currentUser && currentUser.access_token === user_token_info) {
        setUser({
          ...currentUser,
          type: typeURL,
          device: deviceURL,
        });
        setLoadingInitial(false);
      } else {
        let newUser: any = jwt(user_token_info as string);

        newUser = {
          dob: newUser?.dob,
          ekyc_status: newUser?.ekyc_status,
          full_name: newUser?.full_name,
          gender: newUser?.gender,
          access_token: user_token_info,
          type: "Mobile",
          device: deviceURL,
          mobile_padding_top: mobilePaddingTop || "22",
        };
        // const newUserToken = encodeURI(user_token_info);
        user_token_info = "";
        currentUser = null;
        setUser(newUser);
        localStorage.setItem("user", JSON.stringify(newUser));

        navigate(`/`);
      }
    }
  };

  useEffect(() => {
    let currentUser: any =
      localStorage.getItem("user") == null
        ? null
        : JSON.parse(localStorage.getItem("user") as string);

    if (typeURL === "Mobile") {
      setLoadingInitial(false);
      navigate(`/loading-page?type=Mobile&device=${deviceURL}`);
    } else {
      if (currentUser) {
        setUser(currentUser);
        setLoadingInitial(false);
      } else {
        if (routeGift === "/gift") {
          let accessToken: any = null;
          let giftData: any = null;
          let accountGiftID: any = null;
          accessToken = localStorage.getItem("user_access_token");
          giftData = localStorage.getItem("gift_data");
          accountGiftID = JSON.parse(giftData)?.account_gift_id;
          if (!accessToken || !accountGiftID) {
            navigate(`/404`);
          }
        }
        // code cũ
        setLoadingInitial(false);
      }
    }
  }, []);

  useEffect(() => {
    let idInteceptor;
    if (user?.access_token) {
      idInteceptor = axiosInstance.interceptors.request.use(
        axiosInstanceIntceptorWithCookie,
        function (error: any) {
          return Promise.reject(error);
        }
      );
    } else {
      if (idInteceptor) {
        axiosInstance.interceptors.request.eject(idInteceptor);
      }
    }
    axiosInstance.interceptors.response.use(
      function (response: any) {
        return response;
      },
      function (error: any) {
        const { data, status } = error.response;
        if (status === 401) {
          localStorage.removeItem("user");
          localStorage.removeItem("user_token");
          setUser(undefined);
          if (typeURL === "Mobile") {
            navigate(`/error-page?type=Mobile`);
          } else {
            navigate(`/login`);
          }
        }
        if (status === 404) {
          if (typeURL === "Mobile") {
            navigate(`/404?type=Mobile`);
          } else {
            navigate(`/404`);
          }
        }
        if (status === 403) {
          if (typeURL === "Mobile") {
            navigate(`/forbidden?type=Mobile`);
          } else {
            navigate(`/forbidden`);
          }
        }
        if (status === 500) {
          if (typeURL === "Mobile") {
            navigate(`/505?type=Mobile`);
          } else {
            navigate(`/505`);
          }
        }
        return Promise.reject(error);
      }
    );
  }, [user?.access_token]);

  const loginCallback = async (): Promise<void> => {
    let authedUser: any = await AuthService.loginCallback();
    authedUser = !authedUser ? await AuthService.getUser() : authedUser;
    if (authedUser) {
      let newUser: any = jwt(authedUser.access_token as string);
      newUser = {
        dob: newUser?.dob,
        ekyc_status: newUser?.ekyc_status,
        full_name: newUser?.full_name,
        gender: newUser?.gender,
        access_token: authedUser.access_token,
        type: null,
        device: null,
      };
      setUser(newUser);
      localStorage.setItem("user", JSON.stringify(newUser));
      sessionStorage.clear();
      navigate("/");
    }
  };

  const login = async (): Promise<void> => {
    AuthService.login();
  };

  const logout = () => {
    AuthService.logout();
    setUser(undefined);
    localStorage.removeItem("user");
    navigate("/login");
  };

  const getTokenFromMobile = () => {
    let exchangeToken: any = null;
    let accessToken: any = null;
    let giftData: any = null;
    let accountGiftID: any = null;
    let sender_name: any = null;
    const startTime = new Date().getTime();

    setTimeout(() => {
      const setintervalId = setInterval(() => {
        exchangeToken = localStorage.getItem("user_token");
        accessToken = localStorage.getItem("user_access_token");
        giftData = localStorage.getItem("gift_data");
        accountGiftID = JSON.parse(giftData)?.account_gift_id;
        sender_name = JSON.parse(giftData)?.sender_name;

        let newUser: any = jwt(exchangeToken as string);
        if (accessToken && accountGiftID) {
          newUser = {
            dob: newUser?.dob,
            ekyc_status: newUser?.ekyc_status,
            full_name: newUser?.full_name,
            gender: newUser?.gender,
            user_access_token: accessToken,
            account_gift_id: accountGiftID,
            sender_name: sender_name,
            type: "Mobile",
            device: deviceURL,
            mobile_padding_top: mobilePaddingTop || "22",
          };

          setUser(newUser);
          localStorage.setItem("user", JSON.stringify(newUser));
          setLoadingInitial(false);
          clearInterval(setintervalId);
          navigate(`/gift`);
          return;
        }
        if (exchangeToken) {
          newUser = {
            dob: newUser?.dob,
            ekyc_status: newUser?.ekyc_status,
            full_name: newUser?.full_name,
            gender: newUser?.gender,
            access_token: exchangeToken,
            user_access_token: accessToken,
            account_gift_id: accountGiftID,
            type: "Mobile",
            device: deviceURL,
            mobile_padding_top: mobilePaddingTop || "22",
          };

          setUser(newUser);
          localStorage.setItem("user", JSON.stringify(newUser));
          setLoadingInitial(false);
          clearInterval(setintervalId);
          navigate(`/`);
          if(routeGift.startsWith('/tttd-gold')) {
            if(routeGift === '/tttd-gold/home') {
              if(show_welcome === 'false') {
                navigate(`/tttd-gold/overview?type=Mobile&paddingTop=${mobilePaddingTop}&device=${deviceURL}&show_welcome=${show_welcome}&activeDate=${activeDate}&expireDate=${expireDate}&theme=${theme}`);
              }
              else {
                navigate(`${routeGift}?type=Mobile&device=${deviceURL}&paddingTop=${mobilePaddingTop}&activeDate=${activeDate}&expireDate=${expireDate}&theme=${theme}`);
              }
            }
            else {
              navigate(`${routeGift}?type=Mobile&device=${deviceURL}&paddingTop=${mobilePaddingTop}&theme=${theme}`);
            }
          }
        } else if (new Date().getTime() - startTime >= 1500) {
          getUserToken();
          clearInterval(setintervalId);
        }
      }, 200);
    }, 100);
  };

  const value = useMemo(
    () => ({
      user,
      loading,
      error,
      logout,
      login,
      setError,
      loginCallback,
      getTokenFromMobile,
    }),
    [user, loading, error]
  ) as any;

  return (
    <AuthContext.Provider value={value}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
