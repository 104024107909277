import React from 'react';
import { UserType } from '@shared/types/User.type';
import { NormalResponseError } from '@shared/types/Common.type';

interface AuthContextType {
  user?: UserType;
  loading: boolean;
  error?: NormalResponseError;
  setError?: (payload: string | undefined) => void;
  login: () => void;
  loginMS: (code: string, state: string) => void;
  logout: () => void;
  loginCallback: () => void;
  getTokenFromMobile: () => void;
}

const AuthContext = React.createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return React.useContext(AuthContext);
}

export default AuthContext;