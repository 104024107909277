import React from 'react';
import Routes from '@core/routes/Routes';
import AuthProvider from '@core/contexts/Auth/AuthProvider';

const App: React.FC = () => (
  <div>
    <AuthProvider>
      <Routes />
    </AuthProvider>
  </div>
);

export default App;
