import React from 'react';
import Overview from 'assets/img/overview.png'
import { useNavigate, useSearchParams } from 'react-router-dom';

const TTTDGoldHomePage = () => {
    const navigator = useNavigate();
    const [searchParams] = useSearchParams();
    const activeDate = searchParams.get('activeDate');
    const expireDate = searchParams.get('expireDate');
    const deviceURL = searchParams.get("device");
    const mobilePaddingTop = searchParams.get("paddingTop");
    const theme = searchParams.get('theme');

    return (
        <div className={`${theme !== 'darkmode' ? 'bg-background_gradient_light text-black' : 'bg-background_gradient text-white'} flex flex-col flex-1 pt-11`}>
            <img src={Overview} alt="" />
            <div className='px-[16px] pb-[29px] gap-[16px] flex flex-col flex-1'>
                <h1 className='text-[28px] font-semibold leading-[34px] tracking-[0.14px] text-center'>
                    Chào mừng bạn đến Tititada Gold
                </h1>
                <p className='text-[14px] font-normal leading-[20px] tracking-[0.084px] text-center'>
                    Mở khoá tính năng vững bước trên hành trình tài <br/> chính
                </p>
                <div className='pt-[16px] px-[4px] flex flex-1 items-end'>
                    <button className={`h-[56px] w-full rounded-[100px] ${theme !== 'darkmode' ? 'bg-yellow' : 'bg-button_linner_gradient'}`} onClick={() => {
                        navigator(`/tttd-gold/overview?type=Mobile&device=${deviceURL}&paddingTop=${mobilePaddingTop}&activeDate=${activeDate}&expireDate=${expireDate}&theme=${theme}`)
                    }}>
                        <p className={`text-black text-[15px] font-bold leading-[20px] tracking-[-0.165px]`}>Tiếp theo</p>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default TTTDGoldHomePage;