import { LoadingBarProcess } from '@shared';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MasterLayout from '@shared/layouts/MasterLayout';

const HomePage = React.lazy(() => import('pages/Home/Home'));

const HomeRoutes = () => {
  return (
    <Routes>
        <Route path="/" element={<MasterLayout />}>
            <Route
                index
                element={
                    <React.Suspense fallback={<LoadingBarProcess />}>
                        <HomePage />
                    </React.Suspense>
                }
            />
        </Route>
    </Routes>
  )
}

export default HomeRoutes