import React from "react";
import img505 from 'assets/svg/505.svg';
import BtnBack from 'assets/img/btn_back.png';
import {closeFunc} from 'assets/js/gift_style';

export const Page505 = () => {
    const handleBack = () => {
        closeFunc();
    };
    return(
        <>
            <div className=" bg-yellow min-h-[100vh]">
                <div className="relative pt-[50px] h-[100px]">
                    <div className="back w-[40px] h-[40px] ml-5" onClick={handleBack}>
                        <img src={BtnBack} alt='btn_back'/>
                    </div>
                </div>
                <div className="flex items-center justify-center pt-[100px]">
                    <img src={img505} alt="" className="max-w-full w-[150px]" />
                </div>
                <div className="pt-10">
                    <h2 className="text-[50px] font-bold text-black text-center">500</h2>
                    <p className="text-[20px] font-medium text-black text-center">Internal Server Error</p>
                </div>
            </div>
        </>
    )
}