export const closeFunc = () => {
  window.flutter_inappwebview.callHandler('handlerClose', "Tien test")
    .then(function(result) {
      console.log(JSON.stringify(result));
  });    
}

export const closeFuncWithMess = (value) => {
  window?.flutter_inappwebview?.callHandler('handlerClose', {"action_type": value})
    .then(function(result) {
      console.log(JSON.stringify(result));
  });    
}

export const closeFuncWithPayload = (value) => {
  window.flutter_inappwebview.callHandler('handlerClose', value)
    .then(function(result) {
      console.log(JSON.stringify(result));
  }); 
}

export const playSound = () => {
  window.flutter_inappwebview.callHandler('handlerSound', 'jingle')
    .then(function(result) {
      console.log(JSON.stringify(result));
  }); 
}

export const getToken = () => {
  const reponse = window.flutter_inappwebview.callHandler('saveLocalStorage')
    .then(function(result) {
      // print to the console the data coming
      // from the Flutter side.
      if (result) {
        return JSON.stringify(result);
      }
      // window.flutter_inappwebview.callHandler('handlerFooWithArgs', 1, true, ['bar', 5], {foo: 'baz'}, result);
  });

  return reponse;
}

