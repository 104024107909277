import React from 'react';
import OverviewBanner from 'assets/img/overviewBanner.png'
import OverviewBanner_light from 'assets/img/overviewBanner_light.png'
import { closeFuncWithMess } from 'assets/js/gift_style';
import MoneyIcon from 'assets/img/MoneyIcon.png'
import MoneyIcon_light from 'assets/img/MoneyIcon_light.png'
import SignIcon from 'assets/img/SignIcon.png'
import SignIcon_light from 'assets/img/SignIcon_light.png'
import ExpertIcon from 'assets/img/ExpertIcon.png'
import ExpertIcon_light from 'assets/img/ExpertIcon_light.png'
import IslandIcon from 'assets/img/IslandIcon.png'
import IslandIcon_light from 'assets/img/IslandIcon_light.png'
import CoolFaceIcon from 'assets/img/coolFaceIcon.png'
import CoolFaceIcon_light from 'assets/img/coolFaceIcon_light.png'
import { ArrowRight, BackIcon } from 'assets/icon';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

const titleInfoStyle = 'text-grey-dark text-[14px] font-normal leading-[20px]';
const descriptionInfoStyle = 'text-[14px] font-bold leading-[20px]';

const privilegeData = [
    // {
    //     id: 1,
    //     icon: IslandIcon,
    //     icon_light: IslandIcon_light,
    //     title: 'Danh mục thông minh',
    //     description: 'Đầu tư nhẹ nhàng với danh mục từ Tititada'
    // },
    {
        id: 2,
        icon: ExpertIcon,
        icon_light: ExpertIcon_light,
        title: 'Kết nối với chuyên gia',
        description: 'Gặp và lên kế hoạch tài chính cho riêng bạn'
    },
    {
        id: 3,
        icon: MoneyIcon,
        icon_light: MoneyIcon_light,
        title: 'Phí ứng tiền ưu đãi, giảm 50%',
        description: 'Mua và giao dịch tiền nhanh chóng'
    },
    {
        id: 4,
        icon: SignIcon,
        icon_light: SignIcon_light,
        title: 'Báo cáo chuyên sâu',
        description: 'Phân tích từ các công ty, tổng hợp bởi Tititada'
    }
]

const privilegeRegisterData = [
    // {
    //     id: 1,
    //     icon: IslandIcon,
    //     icon_light: IslandIcon_light,
    //     title: 'Danh mục thông minh',
    //     description: 'Đầu tư nhẹ nhàng với danh mục từ Tititada'
    // },
    {
        id: 2,
        icon: ExpertIcon,
        icon_light: ExpertIcon_light,
        title: 'Kết nối với chuyên gia',
        description: 'Gặp và lên kế hoạch tài chính cho riêng bạn'
    },
    {
        id: 3,
        icon: MoneyIcon,
        icon_light: MoneyIcon_light,
        title: 'Miễn phí ứng tiền tự động',
        description: 'Mua và giao dịch tiền nhanh chóng'
    },
    {
        id: 4,
        icon: CoolFaceIcon,
        icon_light: CoolFaceIcon_light,
        title: 'Dark mode chuyên nghiệp',
        description: 'Nhìn pro, đầu tư pro'
    },
    {
        id: 5,
        icon: SignIcon,
        icon_light: SignIcon_light,
        title: 'Báo cáo chuyên sâu',
        description: 'Phân tích từ các công ty, tổng hợp bởi Tititada'
    }
    
]

const TTTDGoldOverviewPage = () => {
    const [searchParams] = useSearchParams();
    const activeDate = searchParams.get('activeDate');
    const expireDate = searchParams.get('expireDate');
    const show_welcome = searchParams.get('show_welcome');
    const mobilePaddingTop = parseFloat(searchParams.get("paddingTop") || '0');
    const theme = searchParams.get('theme');

    const handleClickButton = (data: any) => {
        closeFuncWithMess(data);
    };

    return (
        <div className='bg-background_gradient'>
            <div className={`${theme === 'darkmode' ? 'bg-black' : 'bg-[#FADF4A]'}`} style={{
                height: `${mobilePaddingTop}px`
            }}></div>
            <div className={`fixed left-[16px] w-[40px] h-[40px] ${theme !== 'darkmode' ? 'bg-F1D53C' : 'bg-222222'} rounded-[100px] flex justify-center items-center`} 
            style={{
                top: `${mobilePaddingTop + 16}px`
            }}
            onClick={() => {
                handleClickButton('back')
            }}>
                <BackIcon theme={theme}/>
            </div>
            <img src={theme !== 'darkmode' ? OverviewBanner_light : OverviewBanner} alt="" />
            <div className={`px-[16px] pb-[30px] ${theme !== 'darkmode' ? 'bg-grey-light text-black' : 'bg-black text-white'} flex flex-col gap-[32px]`}>
                <div className='flex flex-col gap-[16px]'>
                    <h1 className='text-[28px] font-semibold leading-[34px] tracking-[0.14px]'>Tititada Gold</h1>
                </div>
                <div className='flex flex-col gap-[16px]'>
                    <p className='text-[18px] font-bold leading-[24px] tracking-[0.09px]'>Đặc quyền Gold của bạn</p>
                    <div className='flex flex-col gap-[12px]'>
                        {
                            ((show_welcome === 'false' && (activeDate === 'null' && expireDate === 'null')) ? privilegeRegisterData : privilegeData).map((item) => {
                                return (
                                    <div className={`px-[16px] py-[12px] rounded-[100px] border border-solid ${theme !== 'darkmode' ? 'border-EAEAEA bg-white' : 'border-303030 bg-222'} flex gap-[8px]`} key={item.id}>
                                        <div className='flex justify-center items-center min-w-[27px]'>
                                            <img src={theme !== 'darkmode' ? item.icon_light : item.icon}  alt="" className='w-[27px]'/>
                                        </div>
                                        <div className='flex flex-col gap-[8px]'>
                                            <p className='text-[14px] font-bold leading-[20px]'>{item.title}</p>
                                            <p className='text-[12px] font-normal leading-normal tracking-[0.072px]'>{item.description}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                {
                    (show_welcome === 'false' && (activeDate === 'null' && expireDate === 'null')) ? (
                        <div className='text-[14px] font-bold leading-[20px] text-center'>
                            <p>
                                Miễn phí Tititada Gold với tổng tài sản trên
                            </p>
                            <span>10,000,0000đ</span>
                            {/* <div className='flex gap-2 justify-center'>
                                <del>2,000,000đ</del>
                                <ArrowRight />
                                <span>1,000,0000đ</span>
                            </div> */}
                            <p className='text-grey-dark text-[12px] font-normal leading-[18px] tracking-[-0.012px] mt-2'>*Chương trình áp dụng đến ngày 30/08/2024</p>
                        </div>
                    ) : (
                        <div>
                            <p className='text-[18px] font-bold leading-[24px] tracking-[0.09px]'>Thông tin</p>
                            <div className='flex flex-col gap-[16px] mt-[16px]'>
                                <div className='flex flex-col gap-[8px]'>
                                    <p className={titleInfoStyle}>Điều kiện</p>
                                    <p className={descriptionInfoStyle}>Tổng tài sản trên 10,000,000 đồng</p>
                                </div>
                                <div className='flex flex-col gap-[8px]'>
                                    <p className={titleInfoStyle}>Ngày tính tiếp theo</p>
                                    <p className={descriptionInfoStyle}>{
                                        moment(expireDate).isValid() ? moment(expireDate).format('DD/MM/YYYY') : '-'
                                    }</p>
                                </div>
                                <div className='flex flex-col gap-[8px]'>
                                    <p className={titleInfoStyle}>Bạn đã là thành viên Gold từ</p>
                                    <p className={descriptionInfoStyle}>{
                                        moment(activeDate).isValid() ? moment(activeDate).format('DD/MM/YYYY') : '-'
                                    }</p>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className='flex flex-col gap-[4px] text-[12px] font-normal leading-[18px] tracking-[-0.012px]'>
                    <p className='text-grey-dark'>Nếu có bất kỳ câu hỏi gì, Tititada luôn ở đây để hỗ trợ bạn.</p>
                    <p className='text-FFC930 underline' onClick={() => handleClickButton("42")}>Liên hệ Tititada</p>
                </div>
                {
                    (show_welcome === 'false' && (activeDate === 'null' && expireDate === 'null')) && (
                        <div className='pt-[14px] px-[4px]'>
                            <button className={`h-[56px] w-full rounded-[100px] ${theme !== 'darkmode' ? 'bg-yellow' : 'bg-button_linner_gradient'}`}>
                                <p className='text-black text-[15px] font-bold leading-[20px] tracking-[-0.165px]' onClick={() => handleClickButton("28")}>Nạp tiền ngay</p>
                            </button>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default TTTDGoldOverviewPage;