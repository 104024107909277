import React, { useEffect } from 'react';
import { CircleSpin } from '@shared/components/spinner/CircleSpin';
import { useAuth } from '@core/contexts/Auth/AuthContext';

const LoadingPage = () => {
    const auth = useAuth();

    useEffect(() => {
      auth.getTokenFromMobile();
    }, [])
  return (
    <div className='bg-yellow min-h-screen h-auto overflow-y-auto'>
      <div className='fixed top-0 right-0 left-0 bg-[#000] bg-opacity-30 flex items-center justify-center bottom-0'>
        <div className="flex justify-center items-center text-xl">
            <CircleSpin color="text-primary-main"/>
        </div>
      </div>
    </div>
  )
}

export default LoadingPage