import { LoadingBarProcess } from '@shared';
import MasterLayout from '@shared/layouts/MasterLayout';
import TTTDGoldHomePage from 'pages/TititadaGold/TTTDGoldHomePage';
import TTTDGoldOverviewPage from 'pages/TititadaGold/TTTDGoldOverviewPage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

// const SmartDirectoryPage = React.lazy(() => import('pages/TititadaGold/SmartDirectoryPage'));
const UpdatePage = React.lazy(() => import('pages/UpdatePage/UpdatePage'));
const AutomaticCashAdvancePage = React.lazy(() => import('pages/TititadaGold/AutomaticCashAdvancePage'));
const ResearchFeaturePage = React.lazy(() => import('pages/TititadaGold/ResearchFeaturePage'));

const TititadaGoldRoutes = () => {
    return (
        <Routes>
            <Route path="/smart-directory" element={<MasterLayout />}>
                <Route
                    index
                    element={
                        <React.Suspense fallback={<LoadingBarProcess />}>
                            <UpdatePage />
                        </React.Suspense>
                    }
                />
            </Route>
            <Route path="/automatic-cash-advance" element={<MasterLayout />}>
                <Route
                    index
                    element={
                        <React.Suspense fallback={<LoadingBarProcess />}>
                            <AutomaticCashAdvancePage />
                        </React.Suspense>
                    }
                />
            </Route>
            <Route path="/research-feature" element={<MasterLayout />}>
                <Route
                    index
                    element={
                        <React.Suspense fallback={<LoadingBarProcess />}>
                            <ResearchFeaturePage />
                        </React.Suspense>
                    }
                />
            </Route>
            <Route path="/home" element={<MasterLayout />}>
                <Route
                    index
                    element={
                        <React.Suspense fallback={<LoadingBarProcess />}>
                            <TTTDGoldHomePage />
                        </React.Suspense>
                    }
                />
            </Route>
            <Route path="/overview" element={<MasterLayout />}>
                <Route
                    index
                    element={
                        <React.Suspense fallback={<LoadingBarProcess />}>
                            <TTTDGoldOverviewPage />
                        </React.Suspense>
                    }
                />
            </Route>
        </Routes>
    );
};

export default TititadaGoldRoutes;