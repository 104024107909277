import React, { useEffect } from "react";
import maintain from 'assets/svg/maintain.svg';
import { useNavigate } from "react-router-dom";

export const Maintain = () => {
    const navigate = useNavigate();
    useEffect(() => {
        const appMaintain = localStorage.getItem('appMaintain');
        if (!appMaintain || (appMaintain && JSON.parse(appMaintain) == "false")) {
            navigate("/");
        }
    }, []);
    
    return (
        <>
            <div className="flex flex-col items-center justify-center my-20">
                <div className="flex items-center justify-center w-full">
                    <img src={maintain} alt="" className="max-w-full" />
                </div>
                <div className="flex items-center justify-center pt-[70px]">
                    <p className="text-[50px] text-center font-medium text-black max-w-[580px] mx-auto leading-[66px]">
                        Hệ thống đang bảo trì, vui lòng quay lại sau nhé.
                    </p>
                </div>
            </div>
        </>
    )
}